import * as React from "react"
import LabsPage from "../ui/pages/LabsPage"

const Page = () => {
  return (
    <LabsPage/>
  )
}

export default Page
