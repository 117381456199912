import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

interface PageProps {
  pageTitle: string;
  pageSubtitle?: string;
  children?: any;
}

const Page = ({ children, pageTitle, pageSubtitle }: PageProps) => {
  return (
    <>
      <Paper elevation={0}>
        <Typography
          variant='h3'
          component='h1'
          align='center'
          gutterBottom={false}
          pt={3.75}
          pb={5}
          mb={5}
        >
          {pageTitle}
          <br/>
          {pageSubtitle}
        </Typography>
      </Paper>
      <Container sx={{ pt: 0, pb: 2 }}>{children}</Container>
    </>
  );
};

export default Page;
