import React from 'react';
import Typography from '@mui/material/Typography';
import Page from '../../components/Page';

export default function LabsPage() {
  return (
    <Page pageTitle='Labs'>
      <Typography variant='h1'>Labs Page</Typography>
    </Page>
  );
}
